.work-email-form {
  margin: 0px auto;
  font-size: 14px;
}
.work-email-form form {
  padding: 0px 50px 0px 50px;
}

.work-email-form .form-group {
  margin-bottom: 0.8rem;
  width: 100%;
}

.work-email-form h2 {
  margin: 0 0 15px;
}

.work-email-form h4 {
  font-weight: 500;
}

.work-email-form .hint-text {
  color: #777;
  padding-bottom: 15px;
  text-align: center;
  font-size: 13px;
}

.work-email-form-select {
  width: 210px;
}

.box {
  width: 600px;
  padding: 0 30px;
}

div.box1 {
  text-align: center;
  background-color: gray;
  color: white;
  min-height: 50px;
}

button.continue {
  background-color: indigo;
  color: white;
}

div.emailbox {
  display: flex;
  margin: 5px;
}

.error-space {
  padding-bottom: 10px;
  color: red;
  text-align: left;
  font-size: 12px;
}

.hidden {
  display: none;
}

.work-email-form .form-label {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #373b3c;
}

.work-email-form .terms {
  color: #525a5c;
  font-size: 12px;
  max-width: 420px
}

.work-email-form .formbox {
  display: flex;
  font-size: 12px;
  color: #525a5c;
  margin: 15px 0px 0px 0px;
}

.work-email-form .checkbox-style {
  height: 20px;
  width: 18px;
  margin-right: 10px;
  vertical-align: middle;
}

.form-container-custom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.social-btn {
  margin: 10px 0;
  font-size: 14px !important;
  text-align: center;
  line-height: 24px;
  font-weight: 600 !important;
}

/*.social-btn:hover {
  background-color: #026276 !important;
}*/

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: rgb(255, 255, 255);
  border: 0.2px solid #737272;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .radio-checkmark {
  background-color: rgb(255, 255, 255);
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .radio-checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

/* Customm Checkbox */

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid rgb(145, 141, 141);
  background-color: #ffff;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Media Queries*/

@media screen and (max-width: 1350px) {
  .work-email-form form {
    width: 65vw;
  }
}

@media screen and (max-width: 900px) {
  .work-email-form form {
    width: 75vw;
  }
}

@media screen and (max-width: 700px) {
  .work-email-form form {
    width: 100vw;
  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057;
  opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #495057;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
  color: #495057;
  }

/* This media query is used for: iPhone 13 mini, iPhone 12 mini, iPhone 11 Pro, iPhone Xs, and iPhone X*/
  /* 2340x1080 pixels at 476ppi */
  @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
      .work-email-form form {
        width: 100vw;
      }
     }

/* This media query is used for: iPhone 13, iPhone 12 and iPhone 12 Pro */
/* 2532x1170 pixels at 460ppi */
@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
      .work-email-form form {
        width: 100vw;
      }
     }

/* This media query is used for: iPhone 13 Pro Max and iPhone 12 Pro Max */
/* 2778x1284 pixels at 458ppi */
@media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) {
      .work-email-form form {
        width: 100vw;
      }
     }

/* This media query is used for: iPhone 11 and iPhone XR */
/* 1792x828px at 326ppi */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) {
      .work-email-form form {
        width: 100vw;
      }
     }

/* This media query is used for: iPhone 11 Pro Max and iPhone Xs Max */
/* 2688x1242px at 458ppi */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3) {
      .work-email-form form {
        width: 100vw;
      }
     }