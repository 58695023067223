.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(3, 114, 158, 0.3);
  border-radius: 50%;
  border-top-color: teal;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
