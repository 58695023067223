.UpdatePasswordContainer {
  min-width: 35%;
  min-height: 50%;

  box-shadow: 1px 2px 2px #073b4c;
  border-radius: 2em;
  border: 1px solid #073b4c;
  background-color: #ffd166;
}

.must-container {
  margin-bottom: 1em;
  border: 0.1em solid #073b4c;
}

.MustContainItem {
  margin-left: 0px;
}

.must-item {
  position: relative;
  min-height: 1.7em;
}

.must-text {
  font-size: 11px;
  font-weight: 700;
  list-style-type: none;
}

.must-enable {
  font-size: 11px;
  font-weight: 700;
  list-style-type: none;
}

.cross-out {
  width: 100%;
}

/* li::before {
    content: "";
    display: inline-block;
    height: 1em;
    width: 0.8em;
    margin-right: 7px;
  
    background-color: red;
    -webkit-mask-image: url("./times-solid.svg");
    -webkit-mask-size: cover;
  }

  .must-enable::before{
    content: "";
    display: inline-block;
    height: 1em;
    width: 1em;
    margin-right: 7px;
  
    background-color: green;
    -webkit-mask-image: url("./check-solid.svg");
    -webkit-mask-size: cover;
} */
