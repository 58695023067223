.social-icon {
  display: block;
  cursor: pointer;
  padding: 15px 5px;
  margin: 0px 10px;
  border-radius: 4px;
  min-width: unset !important;
}

.social-icon:hover {
  background: #e5f4ff;
}

.custom-row-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
